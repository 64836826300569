import PropTypes from "prop-types"
import React from "react"

const Button = React.forwardRef(({ children, href, padding, ...params }, ref) => {
  const className =
    `inline-block py-3 font-extrabold text-xl leading-snug border border-transparent text-base rounded-2xl text-white bg-ckred hover:bg-red-800 focus:outline-none focus:ring transition duration-150 ease-in-out ${padding}`

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </a>
    )
  } else {
    return (
      <button ref={ref} className={className} {...params}>
        {children}
      </button>
    )
  }
})

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
}

Button.defaultProps = {
  href: null,
}

export default Button
